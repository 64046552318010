export const FIRST_NAME_RULES = Object.freeze([
  v => !!v || 'First name is required'
]);

export const LAST_NAME_RULES = Object.freeze([
  v => !!v || 'Last name is required'
]);

export const EMAIL_RULES = Object.freeze([
  v => !!v || 'Email is required',
  v => /.+@.+/.test(v) || 'Email must be valid'
]);

export const PASSWORD_RULES = Object.freeze([
  v => !!v || 'Password is required',
  v => (v && v.length >= 6) || 'Password must be at least 6 characters long'
]);

export const TERMS_AND_CONDITION_RULES = Object.freeze([
  v => !!v || 'Must agree with terms and conditions'
]);

export const CONFIRM_PASSWORD_RULES = Object.freeze([
  v => !!v || 'Confirm password is required'
]);
