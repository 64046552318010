<template lang="pug">
  v-container(fluid).pa-0
    v-row(
      no-gutters
      :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
    ).my-5
      v-col(
        cols="12"
        sm="12"
        md="7"
        lg="6"
      )
        div(:class="$isMediumAndDown ? 'pb-10 px-4' : ''")
          v-img(
            src="@/assets/mmi-sign-up-super-hero.png"
            alt="Super hero"
          )
          h2.opensans.fontsize-24.px-4 I am a hero.
          h1.font-italic.opensans.fontsize-16.mb-0.px-4 The main hope of a nation lies in the proper education of the youth…
          p.font-italic.opensans.fontsize-16.mb-0.px-4 - Erasmus

      v-col(
        cols="12"
        sm="12"
        md="5"
        lg="6"
      )
        //- @submit="signin"
        v-form(
          ref="form"
          v-model="valid"
          lazy-validation
          @submit="signup"
        )
          v-card(flat).transparent
            v-card-title.mb-5
              h2(:class="$isMediumAndDown ? 'mx-auto' : 'mx-0'").mb-8.fontsize-32 Sign Up is easy!
              h2(:class="$isMediumAndDown ? 'mx-auto' : 'mx-0'").opensans.fontsize-20.font-weight-medium Join the league and together let's make education a reality for everyone.
            v-card-text.opensans.fontsize-16
              v-text-field(
                v-model="firstName"
                label="First Name*"
                placeholder="Juan"
                :rules="firstNameRules"
              )
              v-text-field(
                v-model="lastName"
                label="Last Name*"
                placeholder="Dela Cruz"
                :rules="lastNameRules"
              )
              v-text-field(
                v-model="mobileNo"
                v-mask="mask"
                label="Mobile Number"
                prefix="(+63)"
                :placeholder="mask"
              )
              v-text-field(
                v-model="email"
                label="Email*"
                placeholder="email@domain.com"
                :rules="emailRules"
                :error="emailError"
                :error-messages="emailError ? 'Please use another email.' : ''"
                @input="emailError = false"
              )
              v-text-field(
                v-model="password"
                label="Password*"
                placeholder="Password"
                :rules="passwordRules"
                :type="passwordVisible ? 'text' : 'password'"
                :append-icon="passwordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                @click:append="passwordVisible=!passwordVisible"
              )
              v-text-field(
                v-model="confirmPassword"
                label="Confirm password*"
                placeholder="Confirm password"
                :rules="confirmPasswordRules"
                :type="confirmPasswordVisible ? 'text' : 'password'"
                :append-icon="confirmPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :error="confirmPasswordError"
                :error-messages="confirmPasswordMessage"
                @click:append="confirmPasswordVisible =! confirmPasswordVisible"
                @change="checkPasswordEquality"
              )
              v-checkbox(
                v-model="termsAndConditions"
                :rules="termsAndConditionsRules"
                color="primary"
              )
                template(#label)
                  span I agree on the &nbsp
                    span(@click="handleTermsLink").primary--text Terms and Conditions
            v-card-actions.mb-5
              v-btn(
                @click.stop="signup"
                :disabled="!valid"
                :loading="loading"
                x-large
                color="primary"
              ).fontsize-18.text-none Sign Up
    terms-and-conditions-dialog(
      :dialog="dialog"
      @close="dialog = false"
    )
    v-dialog(
      width="450" 
      v-model="successDialog"
      persistent
    )
      v-card
        v-card-title
          v-row(justify="center")
            v-col(cols="4").pa-0.text-center
              v-img(
                src="@/assets/mmi-success-logo.png"
              )
        v-card-text.pb-0
          v-row
            v-col(cols="12").pb-0.text-center
              p.fontsize-28 Sign Up Successful!
              span Please confirm your email for verification purposes.
              p.mb-0 In the meantime, you can continue logging in to your Mighty Minds Account. #[b Thank you!]
        v-card-actions.py-8
          v-spacer
          v-btn(
            depressed
            x-large
            color="primary"
            @click="handleSuccess"
          ).text-none.fontsize-20 Get Started
          v-spacer
</template>

<script>
import TermsAndConditionsDialog from '@/components/TermsAndConditionsDialog';
import { mask } from 'vue-the-mask';
import {
  FIRST_NAME_RULES,
  LAST_NAME_RULES,
  EMAIL_RULES,
  PASSWORD_RULES,
  TERMS_AND_CONDITION_RULES,
  CONFIRM_PASSWORD_RULES
} from './constants';

export default {
  directives: { mask },
  components: { TermsAndConditionsDialog },
  data: () => ({
    test: false,
    loading: false,
    valid: false,
    firstName: '',
    firstNameRules: FIRST_NAME_RULES,
    lastName: '',
    lastNameRules: LAST_NAME_RULES,
    mobileNo: '',
    email: '',
    emailRules: EMAIL_RULES,
    password: '',
    passwordVisible: false,
    passwordRules: PASSWORD_RULES,
    termsAndConditions: false,
    termsAndConditionsRules: TERMS_AND_CONDITION_RULES,
    dialog: false,
    successDialog: false,
    emailError: false,
    mask: '###-###-####',
    confirmPassword: '',
    confirmPasswordVisible: false,
    confirmPasswordError: false,
    confirmPasswordMessage: '',
    confirmPasswordRules: CONFIRM_PASSWORD_RULES
  }),
  methods: {
    async signup () {
      this.loading = true;
      try {
        await this.$store.dispatch('auth/signup', {
          firstName: this.firstName,
          lastName: this.lastName,
          mobileNo: this.mobileNo,
          email: this.email,
          password: this.password
        });
        this.$ga.event({
          eventCategory: 'button',
          eventAction: 'click-signup-button',
          eventLabel: 'signup-button'
        });
        this.successDialog = true;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      } catch (error) {
        if (error.toString() === 'Error: The email address is already in use by another account.') {
          this.$enqueueSnack(new Error('Email is already taken. Please use another one.'));
          this.emailError = true;
        } else if (error.toString() === 'Error: The email address is improperly formatted.') {
          this.$enqueueSnack(new Error('Invalid email address.'));
          this.emailError = true;
        } else {
          this.$enqueueSnack(error);
        }
      }
      this.loading = false;
    },
    handleTermsLink () {
      this.dialog = true;
      this.$ga.event({
        eventCategory: 'link',
        eventAction: 'click-signup-terms-link',
        eventLabel: 'signup-terms-link'
      });
    },
    handleSuccess () {
      this.successDialog = false;
      this.$ga.event({
        eventCategory: 'button',
        eventAction: 'click-signup-success-button',
        eventLabel: 'signup-success-button'
      });
      this.$router.replace('/');
    },
    checkPasswordEquality () {
      this.confirmPasswordError = this.confirmPassword !== this.password;
      this.confirmPasswordMessage = this.confirmPasswordError ? 'Passwords does not match' : '';
    }
  }
};
</script>

<style scoped>
  h2 {
    word-break: break-word;
  }
</style>